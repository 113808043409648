<template>
  <v-dialog value="1" persistent max-width="500px" z-index="999999">
    <v-form @submit.prevent="submitHandler">
      <v-card elevation="15">
        <v-toolbar elevation="1" dark color="success">
          <v-toolbar-title>
            {{ Strings.LOGIN_FORM_CAPTION }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            :label="Strings.LOGIN_FORM_LOGIN_LABEL"
            v-model.trim="username"
            :error-messages="loginErrors"
            @input="authError = false"
          ></v-text-field>
          <v-text-field
            :label="Strings.LOGIN_FORM_PASSW_LABEL"
            v-model.trim="password"
            :error-messages="passwordErrors"
            @click:append="show = !show"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @input="authError = false"
          ></v-text-field>

          <v-alert v-if="authError" type="error" class="my-3" outlined>
            {{ Strings.AUTH_ERROR }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" text>{{ Strings.LOGIN_FORM_BTN_LABEL }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Strings from '@/store/const/strings'
import { mapActions } from 'vuex/dist/vuex.mjs'
import { mapMutations } from 'vuex'

export default {
  name: 'LoginView',
  validations: {
    username: { required },
    password: { required },
  },
  data: () => ({
    authError: false,
    show: false,
    username: '',
    password: '',
  }),
  computed: {
    Strings() {
      return Strings
    },
    loginErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required &&
        errors.push('Это поле не должно быть пустым')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required &&
        errors.push('Это поле не должно быть пустым')
      return errors
    },
  },
  mounted() {
    this.SET_BLOCK(true)
  },
  methods: {
    ...mapActions(['login']),
    ...mapMutations(['SET_BLOCK']),
    async submitHandler() {
      this.authError = false

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const username = this.username
      const password = this.password

      try {
        await this.login({ username, password })
        this.closeDlg(true)
      } catch (error) {
        this.authError = true
      }
    },

    closeDlg(result) {
      this.SET_BLOCK(false)
      if (this.closeble) {
        this.$close && this.$close(!!result)
      } else {
        this.$router.push(this.$route?.query?.redirect || '/')
      }
    },
  },
}
</script>
