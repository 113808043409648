<template>
  <div>
    <NavigationDrawer />

    <v-app-bar app>
      <v-toolbar-title>
        <BreadCrumb />
      </v-toolbar-title>
      <v-spacer />
      {{ getUserName }}
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="black" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="doLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Strings.APP_EXIT }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Strings from '@/store/const/strings'
import NavigationDrawer from '@/components/NavigationDrawer'
import { mapActions, mapGetters } from 'vuex'
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'defaultLayout',
  components: {
    NavigationDrawer,
    BreadCrumb,
  },
  created() {
    this.check_auth()
  },
  computed: {
    ...mapGetters(['userName']),
    Strings() {
      return Strings
    },
    getUserName() {
      return this.userName
    },
  },
  methods: {
    ...mapActions(['check_auth']),
    async doLogout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/login'))
    },
  },
}
</script>
