<template>
  <v-breadcrumbs :items="bc">
    <template v-slot:divider>
      <v-icon>mdi-arrow-right-thin</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.path"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'BreadCrumb',
  computed: {
    bc() {
      const bc = []
      this.$route.matched.forEach((item, index, { length }) => {
        const b = {}
        const pos = bc.map(e => e.path).indexOf(item.path)

        b.path = '' //item.path || ''
        b.text = item.meta.title || ''
        b.disabled = index === length - 1

        this.$route.meta.title = this.getTitle

        if (pos < 0) bc.push(b)
      })
      return bc
    },
    getTitle() {
      const x = Number(this.$route.params.step) || null

      switch (x) {
        case 1:
          return 'Приветствие'
        case 2:
          return 'Выбор коллег'
        case 3:
          return 'Ответы'
        case 4:
          return 'Завершение'
        default:
          return this.$route.meta?.title || ''
      }
    },
  },
}
</script>

<style scoped>
.crumb-item {
  font-size: 20px !important;
}
</style>
