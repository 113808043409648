import { apiCall } from '@/lib/axiosApi'
import Vue from 'vue'
//import axios from 'axios'

const state = {
  /**
   * СПИСОК ПОЛЬЗОВАТЕЛЕЙ И РОЛЕЙ СИСТЕМЫ
   */
  users: null,
  roles: null,
}

const getters = {
  /**
   * ПОЛУЧАЕМ СПИСОК ПОЛЬЗОВАТЕЛЙ И РОЛЕЙ
   * @param state
   * @returns {null}
   */
  getUsers: state => state.users,
  getRoles: state => state.roles,
}

const mutations = {
  /**
   * УСТАНАВЛИВАЕМ СПИСОК ПОЛЬЗОВАТЕЛЕЙ
   * @param state
   * @param users
   * @constructor
   */
  SET_USERS: (state, users) => {
    state.users = users
  },

  /**
   * УСТАНАВЛИВАЕМ СПИСОК РОЛЕЙ
   * @param state
   * @param roles
   * @constructor
   */
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },

  /**
   * ПЕРЕЗАПИСЫВАЕМ ПОЛЬЗОВАТЕЛЯ В СТОРЕ
   * @param state
   * @param user
   * @constructor
   */
  UPDATE_USER: (state, user) => {
    const i = state.users.findIndex(i => i.id === user.id)

    if (i > -1) {
      Vue.set(state.users, i, {
        ...state.users[i],
        ...user,
      })
    }
  },

  /**
   * ПУШИМ В СТОР НОВОГО ПОЛЬЗОВАТЕЛЯ
   * @param state
   * @param user
   * @constructor
   */
  ADD_USER: (state, user) => {
    state.users.push(user)
  },
}

const actions = {
  /**
   * ЗАГРУЖАЕМ СПИСОК ПОЛЬЗОВАТЕЛЕЙ С СЕРВЕРА, ЕСЛИ ЕГО НЕТ
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   * @constructor
   */
  GET_USERS: async ({ commit, getters }) => {
    if (getters.getUsers && getters.getUsers.length > 0) return
    const data = await apiCall('users')
    commit('SET_USERS', data)
  },

  /**
   * ПЕРЕЗАПИСЫВАЕМ ПОЛЬЗОВАТЕЛЯ
   * @param commit
   * @param updates
   * @returns {Promise<void>}
   * @constructor
   */
  SAVE_USER: async ({ commit }, updates) => {
    const data = await apiCall(`users/${updates.id}`, updates, 'PATCH')
    commit('UPDATE_USER', data)
  },

  /**
   * РУЧНОЕ ДОБАВЛЕНИЕ НОВОГО ПОЛЬЗОВАТЕЛЯ
   * @param commit
   * @param user
   * @returns {Promise<void>}
   * @constructor
   */
  ADD_USER: async ({ commit }, user) => {
    const data = await apiCall('users', user, 'POST')
    commit('ADD_USER', data)
    const { token_reset_password } = { ...data }
    const m = {
      to: user.email,
      subject: 'Ссылка на создание пароля ✔',
      html: `${window.location.origin}/reset-password/${token_reset_password}`,
    }
    return await apiCall(`mailer/send-mail`, m, 'POST')
  },

  /**
   * ЗАПРОС НА СБРОС ПАРОЛЯ И ОТПРАВКА УВЕДОМЛЕНИЯ ПОЛЬЗОВАТЕЛЮ
   * @param _
   * @param user
   * @returns {Promise<*|undefined|boolean>}
   * @constructor
   */
  RESET_PASSWORD_QUERY: async (_, user) => {
    const id = user.id
    if (!id) return
    const { token_reset_password } = await apiCall(
      `users/${id}/generate-reset-password-token`,
      null,
      'POST'
    )
    if (token_reset_password) {
      const link = `${window.location.origin}/reset-password/${token_reset_password}`
      const m = {
        to: user.email,
        subject: 'Ссылка на создание пароля ✔',
        html: `<p>Ссылка для сброса пароля в системе Опрос 360: <br /> <a href="${link}">${link}</a></p>`,
      }
      return await apiCall(`mailer/send-mail`, m, 'POST')
    }

    return false
  },

  /**
   * ЗАГРУЖАЕМ СПИСОК РОЛЕЙ, ЕСЛИ ЕГО НЕТ
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   * @constructor
   */
  GET_ROLES: async ({ commit, getters }) => {
    if (getters.getRoles && getters.getRoles.length > 0) return
    const data = await apiCall('roles')
    commit('SET_ROLES', data)
  },

  /**
   * ПОЛУЧАЕМ ИНФУ О ПОЛЬЗОВАТЕЛЕ
   * @param _
   * @param token
   * @returns {Promise<*|string>}
   * @constructor
   */
  GET_USER_INFO: async (_, token) => {
    return (await apiCall(`auth/get-user-info/${token}`)).email || 'ошибка!!'
  },

  /**
   * СБРОС ПАРОЛЯ
   * @param _
   * @param token
   * @param password
   * @returns {Promise<*|undefined>}
   * @constructor
   */
  RESET_PASSWORD: async (_, { token, password }) => {
    return await apiCall(`auth/reset-password/${token}`, { password }, 'POST')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
