import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StatisticsView from '@/views/statistics/StatisticsView'
import Strings from '@/store/const/strings'
import store from '@/store'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Statistics',
    component: StatisticsView,
    meta: {
      title: 'Статистика',
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: HomeView,
    meta: {
      title: 'Опросы',
      layout: 'DefaultLayout',
    },
    children: [
      {
        path: '/quiz',
        name: 'QuizList',
        component: () =>
          import(/* webpackChunkName: "Quiz" */ '@/views/QuizListView'),
        meta: {
          layout: 'DefaultLayout',
          title: 'Список опросов',
        },
      },
      {
        path: ':id(\\d+)',
        name: 'QuizView',
        component: () =>
          import(/* webpackChunkName: "Quiz" */ '@/views/QuizView'),
        meta: {
          layout: 'DefaultLayout',
          title: 'Редактировать опрос',
        },
      },
      {
        path: 'new',
        name: 'QuizNew',
        component: () =>
          import(/* webpackChunkName: "Quiz" */ '@/views/QuizView'),
        meta: {
          layout: 'DefaultLayout',
          title: 'Новый опрос',
        },
      },
      {
        path: 'load',
        name: 'QuizLoad',
        component: () =>
          import(/* webpackChunkName: "Quiz" */ '@/views/QuizLoadView'),
        meta: {
          layout: 'DefaultLayout',
          title: 'Загрузка опроса',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/LoginView'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Вход в программу',
      requireAuth: false,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/UsersView'),
    meta: {
      layout: 'DefaultLayout',
      title: 'Пользователи',
    },
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/SetupView'),
    meta: {
      layout: 'DefaultLayout',
      title: 'Настройки программы',
    },
  },
  {
    path: '/qz/:id',
    name: 'Processing',
    component: () =>
      import(
        /* webpackChunkName: "process" */ '@/views/processing/UsersHomeView'
      ),
    meta: {
      layout: 'UserLayout',
      title: 'Опрос 360',
      requireAuth: false,
      user: true,
    },
    children: [
      {
        path: 'quiz/:step',
        name: 'ProcessingQuiz',
        component: () =>
          import(
            /* webpackChunkName: "process" */ '@/views/processing/QuizView'
          ),
        meta: {
          layout: 'UserLayout',
          // title: 'Выполение',
          requireAuth: true,
          user: true,
        },
      },
    ],
  },
  {
    path: '/final',
    name: 'Final',
    component: () =>
      import(/* webpackChunkName: "process" */ '@/views/processing/QuizFinal'),
    meta: {
      layout: 'EmptyLayout',
      requireAuth: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/ResetPasswordView'),
    meta: {
      layout: 'EmptyLayout',
      requireAuth: false,
    },
  },
  {
    path: '/page-not-found',
    name: 'page-not-found',
    alias: '*',
    meta: {
      layout: 'EmptyLayout',
      title: 'Страница не найдена',
      requireAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "p404" */ '@/views/Page404View'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const docTitle = to => {
  return to.meta.title ?? Strings.APP_NAME
}

const isAuthorized = () => {
  return store.getters.loggedIn
}

router.beforeEach((to, from, next) => {
  if (store.getters.getBlock) return next(false)
  const ra = to.meta.requireAuth ?? true
  const isUser = to.meta.user ?? false

  if (ra) {
    if (isUser) {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + store.getters.getProcToken
    } else {
      if (!isAuthorized()) {
        const redirect = to.fullPath ?? undefined
        return next({ name: 'Login', query: { redirect } })
      }
    }
  }

  document.title = docTitle(to)

  next()
})

export default router
