import axios from 'axios'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'

export class EApiException extends Error {
  constructor(code, message) {
    super((code ? `${code} - ` : '') + message)
    this.name = 'EApiException'
    this.code = code
  }
  toString() {
    return `${this.name}: ${this.message}`
  }
}

export class EApiInternalException extends EApiException {
  constructor(
    code = 500,
    message = 'Внутренняя ошибка сервера или сервер не доступен!'
  ) {
    super(code, message)
    this.name = 'EApiInternalException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiConflictException extends EApiException {
  constructor(code = 409, message = 'Конфликт при изменения ресурса') {
    super(code, message)
    this.name = 'EApiConflictException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiBadRequestException extends EApiException {
  constructor(message) {
    super(400, message)
    this.name = 'EApiBadRequestException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiForbiddenException extends EApiException {
  constructor(code = 403, message = 'Нет прав для доступа к ресурсу') {
    super(code, message)
    this.name = 'EApiForbiddenException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

export class EApiUnauthorizedException extends EApiException {
  constructor(code = 401, message = 'Пользователь не авторизован!') {
    super(code, message)
    this.name = 'EApiUnauthorizedException'
    this.message = message
  }
  toString() {
    return `${this.message}`
  }
}

function doAxios(url, data, method) {
  const apiURL = '/api/' + (url ?? '')

  if (method === GET || !method) return axios.get(apiURL)
  if (method === POST) return axios.post(apiURL, data)
  // special way to send axios delete request with body
  if (method === DELETE && data) return axios.delete(apiURL, { data })
  if (method === DELETE) return axios.delete(apiURL)
  if (method === PATCH) return axios.patch(apiURL, data)
  if (method === PUT) return axios.put(apiURL, data)
}

let apiOnUnauthorized = null
let apiOnUnauthorizedLock = 0

export const apiSetUnauthorizedEvent = event => {
  if (typeof event === 'function' || !event) apiOnUnauthorized = event
  else throw new Error('apiSetUnauthorizedEvent error, event is not a function')
}

async function doUnauthorizedEvent() {
  while (apiOnUnauthorizedLock) {
    // если есть блокировка то просто висим 100 мс и ждём
    await new Promise(r => setTimeout(r, 100))
    if (!apiOnUnauthorizedLock) return // выходим молча на 2 попытку
  }
  // если заскочили в проверку ... и есть событие
  if (typeof apiOnUnauthorized === 'function') {
    try {
      apiOnUnauthorizedLock++
      await apiOnUnauthorized()
    } catch {
      throw new EApiUnauthorizedException()
    } finally {
      apiOnUnauthorizedLock--
    }
  } else {
    // Что то сделать если нет авторизации
    console.info('Empty unauthorized event')
    throw new EApiUnauthorizedException()
  }
}

export const apiSetAccessToken = tokken => {
  if (tokken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${String(
      tokken
    ).trim()}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

export const apiCall = async (url, data, method, direct = false) => {
  const retryAuthAndRetryCallFunc = async () => {
    if (direct) {
      throw new EApiUnauthorizedException()
    } else {
      await doUnauthorizedEvent()
      // повторим запрос ???
      return apiCall(url, data, method, true)
    }
  }
  try {
    const { data: resp } = await doAxios(url, data, method)

    if (resp.answer === 'ok') {
      return resp.data
    } else {
      if (resp.statusCode === 401) {
        return retryAuthAndRetryCallFunc()
      } else if (resp.statusCode === 409) {
        throw new EApiConflictException(resp.statusCode, resp.message)
      } else throw new EApiException(resp.statusCode, resp.message)
    }
  } catch (err) {
    if (err.response?.status === 400) {
      throw new EApiBadRequestException(err.response?.data?.message)
    } else if (err.response?.status === 401) {
      return retryAuthAndRetryCallFunc()
    } else if (err.response?.status === 403) {
      throw new EApiForbiddenException()
    } else if (err.response?.status === 409) {
      throw new EApiConflictException()
    } else if ([500, 503].includes(err.response?.status)) {
      throw new EApiInternalException(err.response.status)
    } else if (err.response?.status) {
      throw new EApiException(err.response?.status, err)
    } else throw err
  }
}

export const checkApiServer = async () => {
  try {
    const { data } = await doAxios()
    if (data.answer === 'ok') {
      console.log(data.data)
      return true
    } else {
      return false
    }
  } catch {
    return false
  }
}

export default apiCall
