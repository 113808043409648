import { apiCall } from '@/lib/axiosApi'
import Vue from 'vue'

const state = {
  myQuiz: {},
  answers: [],
}

const getters = {
  getMyQuiz: state => state.myQuiz,
  getMyQuizStatus: state => state.myQuiz?.status?.id || 1,
  getMyQuizUsers: state => state.myQuiz?.users || [],
  getMyQuizHello: state => state.myQuiz?.hello_template || '',
  getMyQuizQuestions: state => state.myQuiz?.questions || [],
  getMyQuizAnswers: state => state.answers || [],
}

const mutations = {
  SET_QUIZ_ANSWERS: async (state, data) => {
    state.answers = data
  },

  /**
   * ВЫБИРАЕМ ВСЕХ ПОЛЬЗОВАТЕЛЕЙ (ОЦЕНИВАЕМЫХ) ДЛЯ КВИЗА
   * @param state
   * @returns {Promise<void>}
   * @constructor
   */
  SET_ALL_USERS: async state => {
    state.myQuiz.users = state.myQuiz.users.map(i => ({ ...i, selected: 1 }))
  },

  /**
   * ОТМЕНЯЕМ ВЫБОР ВСЕХ ПОЛЬЗОВАТЕЛЕЙ (ОЦЕНИВАЕМЫХ) ДЛЯ КВИЗА
   * @param state
   * @returns {Promise<void>}
   * @constructor
   */
  UNSET_ALL_USERS: async state => {
    state.myQuiz.users = state.myQuiz.users.map(i => ({ ...i, selected: 0 }))
  },

  /**
   * ВЫБИРАЕМ ОДНОГО ПОЛЬЗОВАТЕЛЯ (ОЦЕНИВАЕМОГО) ПО ЕГО ИД
   * @param state
   * @param id
   * @returns {Promise<void>}
   * @constructor
   */
  SET_ONE_USER: async (state, id) => {
    const i = state.myQuiz.users.findIndex(i => i.id === id)
    state.myQuiz.users[i].selected = 1
  },

  /**
   * ОТМЕНЯЕМ ВЫБОР ПОЛЬЗОВАТЕЛЯ (ОЦЕНИВАЕМОГО) ПО ЕГО ИД
   * @param state
   * @param id
   * @returns {Promise<void>}
   * @constructor
   */
  UNSET_ONE_USER: async (state, id) => {
    const i = state.myQuiz.users.findIndex(i => i.id === id)
    state.myQuiz.users[i].selected = 0
  },

  /**
   * КВИЗ ТЕКУЩЕГО ПОЛЬЗОВАТЕЛЯ
   * @param state
   * @param quiz
   * @constructor
   */
  SET_MY_QUIZ: (state, quiz) => {
    state.myQuiz = quiz
  },

  SET_MY_ANSWER: (state, answer) => {
    const i =
      state.answers?.findIndex(
        i =>
          i.user_id === answer.user_id && i.question_id === answer.question_id
      ) ?? -1

    if (i > -1) {
      Vue.set(state.answers, i, answer)
    }
  },

  SET_MY_USERS: (state, users) => {
    Vue.set(state.myQuiz, 'users', users)
  },
}

const actions = {
  /**
   * ЗАПИСЫВАЕМ ВЫБРАННЫЕ ОТВЕТЫ (КАЖДЫЙ)
   * @param commit
   * @param answer
   * @returns {Promise<void>}
   * @constructor
   */
  SET_MY_ANSWER: async ({ commit }, answer) => {
    commit('SET_MY_ANSWER', { ...answer, sending: true, error: undefined })
    try {
      const data = await apiCall(`quiz/answer`, answer, 'POST')
      commit('SET_MY_ANSWER', data)
    } catch (error) {
      commit('SET_MY_ANSWER', { ...answer, error: true })
      throw error
    }
  },

  /**
   * ВЫБИРАЕМ ИЗ БАЗЫ КВИЗ ПОЛЬЗОВАТЕЛЯ
   * @param commit
   * @returns {Promise<void>}
   * @constructor
   */
  GET_MY_QUIZ: async ({ commit }) => {
    const data = await apiCall('quiz/my')
    commit('SET_MY_QUIZ', data)
  },

  /**
   * ПОЛУЧАЕМ СВОИ ОТВЕТЫ
   * @param commit
   * @returns {Promise<void>}
   * @constructor
   */
  GET_MY_ANSWERS: async ({ commit }) => {
    const data = await apiCall('quiz/answer')
    commit('SET_QUIZ_ANSWERS', data)
  },

  /**
   * ПОЛУЧАЕМ ВЫБРАННЫХ ДЛЯ ОЦЕНКИ ПОЛЬЗОВАТЕЛЕЙ + ПЕРЕЧИТЫВАЕМ ОТВЕТЫ
   * @param commit
   * @param dispatch
   * @param selected
   * @returns {Promise<void>}
   * @constructor
   */
  UPDATE_SELECTED_USERS: async ({ commit, dispatch }, selected) => {
    const data = await apiCall('quiz/choise', { selected }, 'POST')
    commit('SET_MY_USERS', data.users)
    await dispatch('GET_MY_ANSWERS')
  },

  /**
   * ОКОНЧАНИЕ ОПРОСА
   * @returns {Promise<void>}
   * @constructor
   */
  COMPLETE_QUIZ: async () => {
    await apiCall(`quiz/done`, null, 'POST')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
