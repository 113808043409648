<template>
  <v-container>
    <div>Мега статистика, графики и пр.</div>
  </v-container>
</template>

<script>
export default {
  name: 'StatisticsView',
}
</script>
