import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DefaultLayout from './views/layouts/defaultLayout'
import EmptyLayout from './views/layouts/emptyLayout'
import UserLayout from './views/layouts/userLayout'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueApexCharts from 'vue-apexcharts'
import * as ModalDialogs from 'vue-modal-dialogs'

Vue.config.productionTip = false

const toastOptions = {
  timeout: 2000,
}
Vue.use(Vuelidate)
Vue.use(Toast, toastOptions)
Vue.use(VueApexCharts)

Vue.component('DefaultLayout', DefaultLayout)
Vue.component('EmptyLayout', EmptyLayout)
Vue.component('UserLayout', UserLayout)
Vue.component('ApexChart', VueApexCharts)
Vue.use(ModalDialogs) // No options

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
