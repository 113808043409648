<template>
  <v-navigation-drawer app :mini-variant.sync="mini" permanent>
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="@/assets/cekkmp.svg" width="40px"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ Strings.APP_NAME }}
        </v-list-item-title>
        <v-list-item-subtitle> {{ Strings.ORG_NAME }} </v-list-item-subtitle>
      </v-list-item-content>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left </v-icon>
      </v-btn>
    </v-list-item>

    <v-list dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <div v-for="item in items" :key="item.title">
          <v-list-item link :to="item.link" v-if="isProcessing(item)" dense>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-title>
          </v-list-item>

          <!--<v-list-item
            link
            :to="item.link"
            v-if="item.children.length === 0"
            dense
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-title>
          </v-list-item>
          <v-list-group no-action v-else>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon], i) in item.children"
            :key="i"
            link
          >
            <v-list-item-icon style="padding-left: 0 !important">
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="title"></v-list-item-title>
          </v-list-item>
        </v-list-group>-->
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Strings from '@/store/const/strings'

export default {
  name: 'NavigationDrawer',
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedItem: 0,
    mini: false,
    items: [
      {
        title: 'Статистика',
        icon: 'mdi-chart-line',
        link: '/',
        children: [],
      },
      {
        title: 'Опросы',
        icon: 'mdi-chat-question-outline',
        link: '/quiz',
        children: [],
      },
      {
        title: 'Пользователи',
        icon: 'mdi-card-account-mail-outline',
        link: '/users',
        children: [],
      },
      // {
      //   title: 'Настройки',
      //   icon: 'mdi-cog-outline',
      //   link: '/setup',
      //   children: [],
      // },
      /*{
        title: 'Сотрудники',
        icon: 'mdi-account-supervisor',
        link: '/processing/users',
        processing: true,
        children: [],
      },*/
      /*{
        title: 'Опрос',
        icon: 'mdi-format-vertical-align-top',
        link: '/processing/quiz',
        processing: true,
        children: [],
      },*/
      /*{
        title: 'Меню',
        icon: 'mdi-help-box',
        children: [['Подменю', 'mdi-circle']],
      },*/
    ],
  }),
  computed: {
    Strings() {
      return Strings
    },
  },
  methods: {
    isProcessing(item = []) {
      if (!this.processing) {
        return !item.processing ?? true
      } else {
        return item.processing
      }
    },
  },
}
</script>

<style>
.v-navigation-drawer {
  background: linear-gradient(90deg, #fff 45%, #f5f5f5 76%);
}
</style>
