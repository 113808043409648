import Vue from 'vue'
import Vuex from 'vuex'
import Login from '@/store/modules/Login'
import UsersDict from '@/store/modules/UsersDict'
import QuizzesDict from '@/store/modules/QuizzesDict'
import procLogin from '@/store/modules/pLogin'
import Processing from '@/store/modules/Processing'

Vue.use(Vuex)

export default new Vuex.Store({
  //strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Login,
    UsersDict,
    QuizzesDict,
    procLogin,
    Processing,
  },
})
