<template>
  <div>
    <v-app-bar app>
      <v-toolbar-title>
        <BreadCrumb />
      </v-toolbar-title>
      <v-spacer />
      {{ getUserName }}
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'userLayout',
  components: {
    BreadCrumb,
  },
  computed: {
    ...mapGetters(['getProcAuth', 'getProcUserName']),
    getUserName() {
      return this.getProcUserName || ''
    },
    getAuth() {
      return this.getProcAuth
    },
  },
}
</script>
