import { apiCall } from '@/lib/axiosApi'
import Vue from 'vue'

const state = {
  quizzes: [],
  quiz: {},
  quizNotifyStatus: [],
  userStarData: [],
  fineTuning: [],
  hasChanges: false,
}

const getters = {
  getQuizzes: state => state.quizzes,
  getQuiz: state => state.quiz,
  getQuizDescription: (_, getters) => getters?.getQuiz?.description || '',
  getQuizCategory: state => state.quiz?.categorys || [],
  getQuizId: (_, getters) => getters?.getQuiz?.id || 0,
  getQuizNotifyStatus: state => state.quizNotifyStatus,
  getUserStarData: state => state.userStarData || {},
  getFineTuning: state => state.fineTuning || {},
  getHasChanges: state => state.hasChanges || false,
}

const mutations = {
  RESET_QUIZ_DATA: state => {
    state.quizzes = []
    state.quiz = {}
    state.quizNotifyStatus = []
    state.userStarData = []
    state.fineTuning = []
    state.hasChanges = false
  },

  SET_HAS_CHANGES: (state, value) => {
    state.hasChanges = value
  },

  SET_FINE_TUNING: (state, data) => {
    const { time_edit } = { ...data }
    state.fineTuning = data
    state.quiz.time_edit = time_edit
  },

  SET_QUIZZES: (state, quizzes) => {
    state.quizzes = quizzes
  },

  SET_QUIZ: (state, quiz) => {
    state.quiz = quiz
  },

  SET_QUIZ_NOTIFY_STATUS: (state, statuses) => {
    state.quizNotifyStatus = statuses
  },

  SAVE_QUIZ: (state, data) => {
    const quizIndex = state.quizzes?.findIndex(({ id }) => id === data.id)
    try {
      if (quizIndex > -1) {
        Vue.set(state.quizzes, quizIndex, {
          ...state.quizzes[quizIndex],
          ...data,
        })
      } else {
        const l = state.quizzes.length + 1
        state.quizzes.push({ ...data, id: l })
      }
    } catch (error) {
      throw new Error('Ошибка сохранения параметров опроса')
    }
  },

  SET_USER_STAR_DATA: (state, data) => {
    state.userStarData = data
  },
}

const actions = {
  /**
   * ПОЛУЧАЕМ ОТЧЕТЫ ПО КВИЗУ
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   * @constructor
   */
  GET_REPORT: async ({ getters }) => {
    const id = getters.getQuizId
    const d = {}
    d.data = await apiCall(`quiz/reports/participants/${id}`)
    d.evaluated = await apiCall(`quiz/reports/evaluated/${id}`)
    return d
  },

  /**
   * РАЗРЕШАЕМ ПОВТОРНОЕ ПРОХОЖДЕНИЕ ОПРОСА
   * @param getters
   * @param users
   * @returns {Promise<void>}
   * @constructor
   */
  REPEAT_QUIZ: async ({ getters }, users) => {
    const id = getters.getQuizId
    await apiCall(`quiz/drop-participants-status/${id}`, { users }, 'POST')
  },

  /**
   * СОХРАНЯЕМ НОВЫЕ НАСТРОЙКИ ВОЗМОЖНОСТИ ОЦЕНИВАНИЯ ПОЛЬЗОВАТЕЛЕЙ
   * @param commit
   * @param getters
   * @param sets
   * @returns {Promise<void>}
   * @constructor
   */
  SAVE_FINE_TUNING: async ({ commit, getters }, sets) => {
    const id = getters.getQuizId
    const data = await apiCall(`quiz/set-participants/${id}`, sets, 'POST')
    commit('SET_FINE_TUNING', data)
  },

  /**
   * ПОЛУЧАЕМ НАСТРОЙКИ ТОНКОЙ НАСТРОЙКИ ВОЗМОЖНОСТИ ОЦЕНКИ
   * @param commit
   * @param getters
   * @param users
   * @returns {Promise<void>}
   * @constructor
   */
  GET_FINE_TUNING: async ({ commit, getters }, users) => {
    const id = getters.getQuizId
    const data = await apiCall(`quiz/get-participants/${id}`, { users }, 'POST')
    commit('SET_FINE_TUNING', data)
  },

  /**
   * ПОЛУЧАЕМ СПИСОК ОПРОСОВ
   * @param commit
   * @param getters
   * @param force
   * @returns {Promise<void>}
   * @constructor
   */
  GET_QUIZZES: async ({ commit, getters }, force = false) => {
    if (!force && getters.getQuizzes && getters.getQuizzes.length > 0) return
    const data = await apiCall('quiz?all')
    commit('SET_QUIZZES', data.items)
  },

  /**
   * ПОЛУЧАЕМ ОПРОС ПО ЕГО ИД
   * @param commit
   * @param id
   * @returns {Promise<void>}
   * @constructor
   */
  GET_QUIZ_BY_ID: async ({ commit }, id = 0) => {
    const data = await apiCall(`quiz/${id}`)
    commit('SET_QUIZ', data)
  },

  /**
   * ПОЛУЧАЕМ ДЕТАЛЬНУЮ ИНФОРМАЦИЮ ОЦЕНКИ ПОЛЬЗОВАТЕЛЯ (СО ЗВЕЗДОЙ)
   * @param commit
   * @param getters
   * @param user
   * @returns {Promise<void>}
   * @constructor
   */
  GET_STAR_INFO: async ({ commit, getters }, user) => {
    const id = getters.getQuizId
    const data = await apiCall(`quiz/${id}/${user}?detail`)
    commit('SET_USER_STAR_DATA', data)
  },

  /**
   * ОТПРАВКА УВЕДОМЛЕНИЙ ВСЕМ ПОЛЬЗОВАТЕЛЯ ПО ПОЧТЕ
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   * @constructor
   */
  SEND_NOTIFY_BY_EMAIL_TO_ALL: async ({ commit, getters }) => {
    const id = getters.getQuizId
    const data = await apiCall(`quiz/invites/${id}`, null, 'POST')
    commit('SET_QUIZ_NOTIFY_STATUS', data)
  },

  /**
   * ОТПРАВКА УВЕДОМЛЕНИЯ ВЫБРАННОМУ ПОЛЬЗОВАТЕЛЮ ПО ПОЧТЕ
   * @param commit
   * @param getters
   * @param users
   * @returns {Promise<void>}
   * @constructor
   */
  SEND_NOTIFY_BY_EMAIL_TO_SELECTED: async ({ commit, getters }, users) => {
    const id = getters.getQuizId
    const data = await apiCall(`quiz/invites/${id}`, { users }, 'POST')
    commit('SET_QUIZ_NOTIFY_STATUS', data)
  },

  /**
   * ПОЛУЧАЕМ СТАТУСЫ ДОСТАВКИ УВЕДОМЛЕНИЙ ПО ПОЧТЕ
   * @param commit
   * @param getters
   * @returns {Promise<void>}
   * @constructor
   */
  GET_NOTIFY_STATUS: async ({ commit, getters }) => {
    const id = getters.getQuizId
    if (id) {
      const data = await apiCall(`quiz/invites-statuses/${id}`)
      commit('SET_QUIZ_NOTIFY_STATUS', data)
    }
  },

  /**
   * СОХРАНЯЕМ ОПРОС
   * @param commit
   * @param getters
   * @param quiz
   * @returns {Promise<void>}
   * @constructor
   */
  SAVE_QUIZ: async ({ commit, getters, dispatch }, quiz) => {
    const id = quiz.id
    const data = await apiCall(
      `quiz/${id}`,
      { ...quiz, time_edit: getters.getQuiz.time_edit },
      'PATCH'
    )
    commit('SET_QUIZ', data)
    await dispatch('GET_QUIZZES', true)
  },

  DELETE_QUIZ: async ({ dispatch }, quiz_id) => {
    await apiCall(`quiz/${quiz_id}`, null, 'DELETE')
    await dispatch('GET_QUIZZES', true)
  },

  /**
   * АПЛОДЮ НОВЫЙ КВИЗ ИЗ ФАЙЛА И ОБНОВЛЯЮ СПИСОК КВИЗОВ В СТОРЕ
   * @param dispatch
   * @param file_data
   * @returns {Promise<void>}
   * @constructor
   */
  UPLOAD_QUIZ: async ({ dispatch }, file_data) => {
    const { original_filename, data } = { ...file_data }
    await apiCall(`quiz/import`, { original_filename, data }, 'POST')
    await dispatch('GET_QUIZZES', true)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
