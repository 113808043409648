import {
  apiCall,
  apiSetUnauthorizedEvent,
  apiSetAccessToken,
} from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'

const cUserParam = 'pUser'
const cTokenParam = 'Token'

function clearUnauthorizedEvent() {
  apiSetUnauthorizedEvent(null)
}

const state = {
  procUser: ls.loadDictDataDef(cUserParam, {}, '', cTokenParam),
  procToken: ls.load('pUserToken'),
}

const getters = {
  getProcAuth: state => !!state.procToken,
  getProcToken: state => state.procToken,
  getProcUser: state => state.procUser,
  getProcUserName: state => state.procUser?.first_name ?? '',
}

const mutations = {
  SET_PROC_USER: (state, { access_token, user }) => {
    state.procToken = access_token
    state.procUser = user
    clearUnauthorizedEvent()
    apiSetAccessToken(access_token)
  },

  CLEAR_PROC_USER: state => {
    state.procToken = ''
    state.procUser = {}
    ls.clearDict(cUserParam, '', cTokenParam)
  },
}

const actions = {
  GET_PROC_USER: async ({ commit }, candidate) => {
    try {
      const { access_token, user } = await apiCall(
        `auth/login/${candidate}`,
        null,
        null,
        true
      )
      commit('SET_PROC_USER', { access_token, user })
    } catch (error) {
      throw new Error('Ошибка авторизации')
    }
  },

  LOGOUT_PROC_USER: async ({ commit }) => {
    commit('CLEAR_PROC_USER')
    commit('SET_USERS', null)
    commit('SET_ROLES', null)
    commit('RESET_QUIZ_DATA')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
