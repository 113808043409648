import {
  apiCall,
  apiSetAccessToken,
  apiSetUnauthorizedEvent,
} from '@/lib/axiosApi'
import ls from '@/lib/localStorageUtils'
import router from '@/router'

const cUserParam = 'user'
const cTokenParam = 'Token'

import { create } from 'vue-modal-dialogs'
import LoginViewVue from '../../views/LoginView'

const state = {
  user: ls.loadDictDataDef(cUserParam, {}, '', cTokenParam),
  token: ls.load('userToken'),
  authenticating: false,
  block: false,
}

apiSetAccessToken(state.token)

var loginDlg = create(LoginViewVue, 'closeble', 'show-cancel')

function setUnauthorizedEvent() {
  apiSetUnauthorizedEvent(async () => {
    console.log('Hi Unauthorized men, go to Login please')

    const result = await loginDlg(true, false)
    if (!result) throw new Error('Финита ля комедия')
  })
}
function clearUnauthorizedEvent() {
  apiSetUnauthorizedEvent(null)
}

/**
 * ВОЧЕР ДЛЯ ТЕСТОВ ВСЯКОГО
 */
setInterval(() => {
  const token = ls.loadDictVersion(cUserParam, '', cTokenParam)
  if (state.token !== token) {
    state.token = token
    apiSetAccessToken(token)
    console.log('apiSetAccessToken', token)
  }
}, 5000)

const getters = {
  getBlock: state => state.block,
  loggedIn: state => !!state.token,
  loggedToken: state => state.token,
  userRole: state => state.user?.role?.name ?? 'guest',
  userName: state => state.user?.first_name ?? 'Нет имени',
  isAdmin: state => state.user?.id === 1 ?? false,
}

const mutations = {
  SET_BLOCK: (state, value) => {
    state.block = value
  },

  authenticated(state, val) {
    state.authenticating = val
  },

  SET_TOKEN: (state, { token, user }) => {
    state.token = token
    state.user = user
    apiSetAccessToken(token)
    ls.saveDict(cUserParam, token, user, true, '', cTokenParam)
    setUnauthorizedEvent()
  },

  SET_LOGOUT: () => {
    state.token = ''
    state.user = {}

    apiSetAccessToken()
    ls.clearDict(cUserParam, '', cTokenParam)
    clearUnauthorizedEvent()
  },
}

const actions = {
  async check_auth({ commit, dispatch, state }) {
    if (state.authenticating) return
    try {
      commit('authenticated', true)
      apiSetAccessToken(state.token)

      const ax = await apiCall('auth/authenticated')
      if (!ax.authenticated && state.token) {
        throw new Error('Ошибка авторизации')
      }

      commit('SET_TOKEN', { token: state.token, user: ax.user })
    } catch (e) {
      dispatch('logout')
    } finally {
      commit('authenticated', false)
    }
  },

  login: async ({ commit }, candidate) => {
    try {
      const { access_token: token, user } = await apiCall(
        'auth/login',
        candidate,
        'POST',
        true
      )
      commit('SET_TOKEN', { token, user })
    } catch (error) {
      throw new Error('Ошибка авторизации')
    }
  },

  logout: async ({ commit, getters }) => {
    if (!getters.loggedIn) return
    apiCall('auth/logout', undefined, undefined, true)
    commit('SET_LOGOUT')
    commit('SET_USERS', null)
    commit('SET_ROLES', null)
    commit('RESET_QUIZ_DATA')
    const redirect = router.currentRoute.fullPath ?? undefined
    await router.push({ name: 'Login', query: { redirect } }).catch(() => {})
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
