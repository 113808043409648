export default {
  /**
   * ОРГАНИЗАЦИЯ
   */
  ORG_NAME: 'ЦЭККМП',
  /**
   * ПРИЛОЖЕНИЕ
   */
  APP_NAME: 'Опрос 360',
  APP_EXIT: 'Выйти из программы',
  CONFIRM_EXIT: 'Есть изменения в документе, подтверждаете выход?',
  SAVE_BTN_CAPTION: 'Сохранить',
  CLOSE_BTN_CAPTION: 'Закрыть',
  OPEN_BTN_CAPTION: 'Открыть',
  YES_BTN_CAPTION: 'Да',
  NO_BTN_CAPTION: 'Нет',
  ADD_BUTTON_CAPTION: 'Добавить',
  CREATE_BUTTON_CAPTION: 'Создать',
  COMPLETE_BUTTON_CAPTION: 'Завершить опрос',
  ERROR_MESSAGE: 'Ошибка',
  AUTH_ERROR: 'Ошибка авторизации',
  SEND_BTN_CAPTION: 'Отправить',
  DELETE_BTN_CAPTION: 'Удалить',

  QUIZ_DELETE_DIALOG_CAPTION: 'Внимание - удалене опроса!',
  QUIZ_DELETE_DIALOG_TEXT: 'Опрос будет полностью удалён из системы?!',

  /**
   * ФОРМА ВХОДА
   */
  LOGIN_FORM_CAPTION: 'Вход в программу',
  LOGIN_FORM_LOGIN_LABEL: 'Логин',
  LOGIN_FORM_PASSW_LABEL: 'Пароль',
  LOGIN_FORM_BTN_LABEL: 'Вход',
  /**
   * СТРАНИЦА ОПРОСА
   */
  QUIZ_TABLE_LIST_NAME: 'Список опросов',
  QUIZ_TABLE_LOAD_BUTTON: 'Загрузить',
  QUIZ_DATA_TITLE_LABEL: 'Название',
  QUIZ_DATA_DESCRIPTION_LABEL: 'Описание',
  QUIZ_DATA_ACTIVE_LABEL: 'Активный опрос',
  QUIZ_DATA_LETTER_SUBJECT: 'Тема письма',
  QUIZ_ACTION_PANEL_MAKE_EXCEL_BTN_CAPTION: 'Выгрузить результаты',
  QUIZ_ACTION_PANEL_CAPTION: 'Информация',
  QUIZ_ACTION_PANEL_CREATED_CAPTION: 'Создан:',
  QUIZ_ACTION_PANEL_CHANGED_CAPTION: 'Изменен:',
  QUIZ_ACTION_PANEL_PARTICIPANT_CAPTION: 'Участников:',
  QUIZ_ACTION_PANEL_AUTHOR_CAPTION: 'Автор:',
  QUIZ_VIEW_USERS_DEL_DIALOG_TITLE: 'Изменить участие?',
  QUIZ_VIEW_USERS_DEL_DIALOG_TEXT:
    'Участие выбранных пользователй будет изменено. Продолжить?',
  QUIZ_STATUS_TOOLTIP_CAPTION_0: 'Опрос не начинал(а)',
  QUIZ_STATUS_TOOLTIP_CAPTION_1: 'Проходит опрос',
  QUIZ_STATUS_TOOLTIP_CAPTION_2: 'Опрос пройден',
  QUIZ_USERS_CATEGORY_STATUS_1: 'Оценивающие',
  QUIZ_USERS_CATEGORY_STATUS_2: 'Оцениваемые',
  QUIZ_USERS_STAT_VIEW: 'Просмотр статистики',
  QUIZ_USERS_ADD_BUTTON_TOOLTIP: 'Добавить пользователя',
  QUIZ_USERS_SEND_BUTTON_TOOLTIP: 'Отправить уведомление пользователю',
  QUIZ_USERS_ACTION_BUTTON_TOOLTIP: 'Участие пользователя',
  QUIZ_USERS_EVAL_BUTTON_TOOLTIP: 'Настроить оцениваемых пользователей',
  QUIZ_USERS_BLOCK_TITLE: 'Пользователи',
  QUIZ_QUESTIONS_BLOCK_TITLE: 'Категории и вопросы',
  /**
   * СБРОС ОПРОСА ПОЛЬЗОВАТЕЛЮ
   */
  QUIZ_USERS_RESET_TITLE: 'Открыть опрос выбранным пользователям',
  QUIZ_USERS_RESET_TEXT:
    'Выбранным оценивающим пользователям опрос будет открыт, если они его закончили ранее. Открыть опрос?',
  QUIZ_USERS_RESET_TOOLTIP_TITLE: 'Открыть опрос выбранным пользователям',
  /**
   * СТРАНИЦА ПОЛЬЗОВАТЕЛЕЙ
   */
  USERS_TABLE_LIST_NAME: 'Список пользователей',
  USERS_LIST_INACTIVITY_LABEL: 'Пользователь не активен',
  USERS_EDIT_FAM_LABEL: 'Фамилия*',
  USERS_EDIT_NAME_LABEL: 'Имя, Отчество',
  USERS_EDIT_EMAIL_LABEL: 'Почта*',
  USERS_EDIT_ROLE_LABEL: 'Роль пользователя',
  USERS_EDIT_ACTIVITY_LABEL: 'Активен',
  USERS_EDIT_EMPTY_FIO: 'Не указано',
  /**
   * СТРАНИЦА ЗАГРУЗКИ ФАЙЛА ОПРОСА
   */
  QUIZ_UPLOAD_FILE_TITLE: 'Загрузка опроса из файла',
  QUIZ_UPLOAD_FILE_LABEL: 'Файл с описанием опроса (.xlsx)',
  QUIZ_UPLOAD_FILE_BUTTON: 'Загрузить',
  /**
   * РЕДАКТОР ШАБЛОНОВ ПРИВЕТСТВЕННОГО ЭКРАНА И ШАБЛОНА ПИСЬМА
   */
  templateButtons: [
    {
      name: 'Вставить название опроса',
      icon: 'mdi-application',
      text: 'title',
    },
    {
      name: 'Вставить ИО пользователя',
      icon: 'mdi-account-arrow-down',
      text: 'firstName',
    },
    {
      name: 'Вставить ссылку',
      icon: 'mdi-link-variant-plus',
      text: 'link',
    },
    {
      name: 'Вставить описание опроса',
      icon: 'mdi-text-long',
      text: 'description',
    },
    {
      name: 'Вставить тему письма',
      icon: 'mdi-image-text',
      text: 'theme',
    },
  ],
  /* ctb - Custom Toolbar Bottons */
  CTB: [
    {
      type: 'button',
      class: 'ql-bold',
      tooltip: 'Жирный шрифт',
      value: null,
    },
    {
      type: 'button',
      class: 'ql-italic',
      tooltip: 'Наклонный шрифт',
      value: null,
    },
    {
      type: 'button',
      class: 'ql-underline',
      tooltip: 'Подчеркнутый шрифт',
      value: null,
    },
    {
      type: 'button',
      class: 'ql-strike',
      tooltip: 'Перечеркнутый шрифт',
      value: null,
    },
    {
      type: 'button',
      class: 'ql-align',
      tooltip: 'Выравнивание по левому краю',
      value: '',
      disabledField: 'invite_template',
    },
    {
      type: 'button',
      class: 'ql-align',
      tooltip: 'Выравнивание по центру',
      value: 'center',
      disabledField: 'invite_template',
    },
    {
      type: 'button',
      class: 'ql-align',
      tooltip: 'Выравнивание по правому краю',
      value: 'right',
      disabledField: 'invite_template',
    },
    {
      type: 'button',
      class: 'ql-align',
      tooltip: 'Выравнивание по ширине',
      value: 'justify',
      disabledField: 'invite_template',
    },
    {
      type: 'button',
      class: 'ql-list',
      tooltip: 'Нумерованный список',
      value: 'ordered',
    },
    {
      type: 'button',
      class: 'ql-list',
      tooltip: 'Нумерованный список',
      value: 'bullet',
    },
    {
      type: 'select',
      class: 'ql-color',
      tooltip: 'Цвет шрифта',
      value: null,
    },
  ],
  TEMPLATE_LETTER_TITLE: 'Шаблон письма',
  TEMPLATE_LETTER_EDIT_BTN_TOOLTIP: 'Редактировать шаблон письма',
  TEMPLATE_SCREEN_TITLE: 'Шаблон стартового экрана',
  TEMPLATE_SCREEN_EDIT_BTN_TOOLTIP: 'Редактировать шаблон приветствия',
  TEMPLATE_SCREEN_EXPAND_BTN_TOOLTIP: 'Свернуть / развернуть блок',
  TEMPLATE_LETTER_TAB_1: 'Редактор',
  TEMPLATE_LETTER_TAB_2: 'Просмотр',
  TEMPLATE_EDIT_DIALOG_TITLE: 'Редактирование шаблона',
  QUIZ_USERS_TUNE_CAPTION: 'Настройка возможности оценивания',
  /**
   * ПЕРЕМЕННЫЕ СОСТОЯНИЯ action ПОЛЬЗОВАТЕЛЯ В НАСТРОЙКЕ ОПРОСА
   */
  DEL: 'DEL',
  EDIT: 'EDIT',
  NEW: 'NEW',
  /**
   * ШАГИ ОПРОСА ПОЛЬЗОВАТЕЛЯ
   */
  QUIZ_STEPS_TITLE_1: 'Приветствие',
  QUIZ_STEPS_TITLE_2: 'Выбор пользователей',
  QUIZ_STEPS_TITLE_3: 'Опрос',
  QUIZ_STEPS_TITLE_4: 'Завершение опроса',
  QUIZ_STEPS_2_TITLE: 'Выберите оцениваемых коллег',
  QUIZ_STEPS_2_TEXT:
    'Выберите из предложенного списка коллег, с которыми Вы часто взаимодействуете (лично, по почте, по телефону), и точно сможете оценить их профессиональное мастерство',
  QUIZ_STEPS_BTN_ADD_ALL_TOOLTIP: 'Добавить всех к опросу',
  QUIZ_STEPS_BTN_DEL_ALL_TOOLTIP: 'Убрать всех из опроса',
  /**
   * ПРОЦЕСС ОПРОСА
   */
  QUIZ_PROCESS_NEXT_BTN_CAPTION: 'Продолжить',
  QUIZ_PROCESS_BACK_BTN_CAPTION: 'Назад',
  QUIZ_COMMENT_LABEL: 'Ваш комментарий к ответу',
  QUIZ_NO_ANSWERS: 'Нет оценок для этого сотрудника',
  /**
   * СТАТУСЫ ОТПРАВКИ e-mail (EMAIL_NOTIFY_STATUSES)
   */
  ENS: {
    1: {
      text: 'Не отправлялось',
      color: '#f5f5f5',
      icon: 'mdi-email-fast-outline',
    },
    2: {
      text: 'Попытка отправки...',
      color: 'warning',
      icon: 'mdi-spin mdi-selection-ellipse',
    },
    3: {
      text: 'Ошибка отправки',
      color: 'red',
      icon: 'mdi-email-remove-outline',
    },
    4: { text: 'Отправлено', color: 'green', icon: 'mdi-email-check-outline' },
  },
  /**
   * ТЕКСТЫ ОТПРАВКИ E-MAIL
   */
  QUIZ_ACTION_PANEL_SEND_EMAIL_TO_ALL_BTN_CAPTION: 'Отправить приглашения',
  QUIZ_VIEW_USERS_MAIL_DIALOG_TITLE: 'Отправка почты',
  QUIZ_VIEW_USERS_MAIL_DIALOG_TEXT:
    'Выбранным пользователям будет отправлено приглашение пройти опрос. Продолжить?',
  QUIZ_EMAIL_TO_ALL_DIALOG_CAPTION: 'Внимание - отправка приглашений',
  QUIZ_EMAIL_TO_ALL_BTN_CAPTION: 'Отправить всем',
  QUIZ_EMAIL_TO_OTHER_BTN_CAPTION: 'Отправить остальным',
  QUIZ_EMAIL_TO_ALL_DIALOG_TEXT:
    'Будет отправлено приглашение участникам на почту',
  QUIZ_EMAIL_TO_ALL_DIALOG_TEXT_FORCE:
    'В списке есть пользователи, которым ранее уже было выслано приглашение. Что будем делать?',
  /**
   * РАЗДЕЛ ВОПРОСОВ
   */
  QUIZ_QUESTION_CHAPTER_1_NAME: 'Вопросы',
  QUIZ_QUESTION_CHAPTER_2_NAME: 'Ответы',
  QUIZ_QUESTION_ADD_BTN_TOOLTIP: 'Добавить вопрос',
  QUIZ_QUESTION_NEW_CAT_CREATE_BTN_TOOLTIP: 'Добавить новую кактегорию',
  QUIZ_QUESTION_DEL_CAT_CREATE_BTN_TOOLTIP: 'Удалить текущую категорию',
  QUIZ_QUESTION_UP_CAT_BTN_TOOLTIP: 'Поднять категорию в списке',
  QUIZ_QUESTION_DOWN_CAT_BTN_TOOLTIP: 'Опустить категорию в списке',
  QUIZ_QUESTION_NEW_CARD_TITLE: 'Текст вопроса',
  QUIZ_QUESTION_CAT_NAME_LABEL: 'Категория',
  /**
   * РЕГИСТРАЦИЯ И СБРОС ПАРОЛЯ
   */
  USER_REGISTRATION_DONE_CAPTION: 'Успешно!',
  USER_REGISTRATION_DONE_TEXT:
    'На e-mail пользователя отправлена ссылка для создания пароля',
  USER_REGISTRATION_ERROR_CATION: 'Что-то пошло не так',
  USER_REGISTRATION_ERROR_TEXT: 'Попробуйте еще раз',
  USER_REGISTRATION_WINDOW_TITLE: 'Установка пароля',
  USER_REGISTRATION_WINDOW_TEXT: 'Придумайте новый пароль для',
  USER_REGISTRATION_WINDOW_BTN_CAPTION: 'Установить пароль',
  USER_REGISTRATION_WINDOW_PASS1_LABEL: 'Пароль',
  USER_REGISTRATION_WINDOW_PASS2_LABEL: 'Повторите пароль',
  PASSWORDS_MISMATCH: 'Введённые пароли не совпадают',
  PASSWORD_NEEDLE: 'Введите пароль',
  REENTER_PASSWORD: 'Введите пароль повторно',
  /**
   * ОПРОС ЗАВЕРШЕН
   */
  QUIZ_THIS_IS_THE_END:
    'Спасибо за Ваше участие! <br /> Ваши оценки помогут коллегам стать еще успешнее!',
  QUIZ_DONE_TITLE: 'Вы завершили опрос!',
  QUIZ_DONE_MESSAGE:
    '<h3><span style="color: red">Нажимая "Завершить опрос"</span> Вы подтверждаете свои ответы о коллегах и впоследствии <span style="color: red">Вы не сможете изменить какие-либо ответы.</span></h3>',
}
